import { render, staticRenderFns } from "./Job-Report.vue?vue&type=template&id=54c8461e&scoped=true"
import script from "./Job-Report.vue?vue&type=script&lang=js"
export * from "./Job-Report.vue?vue&type=script&lang=js"
import style0 from "./Job-Report.vue?vue&type=style&index=0&id=54c8461e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c8461e",
  null
  
)

export default component.exports